var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Particles',{attrs:{"id":"tsparticles","options":{
            background: {
                color: '#fff',
                size: '100% 100%',
                repeat: 'no-repeat',
                opacity: 0.1,
            },
            fullScreen: { enable: true, zIndex: -1 },
            particles: {
                color: {
                    value: _vm.colors,
                },
                move: {
                    direction: 'bottom',
                    enable: true,
                    outModes: { default: 'out' },
                    size: true,
                    speed: { min: 1, max: 3 },
                },
                number: { value: 700, density: { enable: true, area: 800 } },
                opacity: {
                    value: 1,
                    animation: { enable: false, startValue: 'max', destroy: 'min', speed: 0.3, sync: true },
                },
                rotate: {
                    value: { min: 0, max: 360 },
                    direction: 'random',
                    move: true,
                    animation: { enable: true, speed: 60 },
                },
                tilt: {
                    direction: 'random',
                    enable: true,
                    move: true,
                    value: { min: 0, max: 360 },
                    animation: { enable: true, speed: 60 },
                },
                shape: {
                    type: ['circle', 'square', 'polygon'],
                    options: {
                        polygon: [
                            { sides: 5 },
                            {
                                sides: 6,
                            } ],
                    },
                },
                size: { value: { min: 3, max: 5 } },
                roll: {
                    darken: { enable: true, value: 30 },
                    enlighten: {
                        enable: true,
                        value: 30,
                    },
                    enable: true,
                    speed: { min: 15, max: 25 },
                },
                wobble: { distance: 30, enable: true, move: true, speed: { min: -15, max: 15 } },
            },
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }